import SoftwareSideMenu, {
  MenuItemType,
  PageType,
} from "components/software-sidemenu"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import SoftwareBreadcrumb from "components/software-sidemenu/breadcrumb"
import useTranslations from "components/use-translations"

export default function Ozellikler() {
  const {
    genel: { ozellikler },
    cekilisSiteOzellikler: {
      seoTitle,
      seoDescription,
      paragraf1,
      paragraf2,
      paragraf3,
      paragraf4,
    },
    cekilis: { hizliCekilis, afilliCekilis },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/cekilis-sitesi/ozellikler"
        datePublished="2017-02-09T22:22:55+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
        breadcrumbList={[
          {
            path: "https://www.kodvizit.com/cekilis-sitesi",
            name: "Çekiliş Sitesi",
          },
        ]}
      />
      <Container className="mt-2 mb-5">
        <SoftwareBreadcrumb
          parentPage={PageType.CekilisSite}
          activeMenuItem={MenuItemType.Ozellikler}
        />
        <Row>
          <Col>
            <h1 className="mb-4">{ozellikler}</h1>

            <p>{paragraf1}</p>
            <p>{paragraf2}</p>
            <h3>{hizliCekilis}</h3>
            <p>{paragraf3}</p>
            <h3>{afilliCekilis}</h3>
            <p>{paragraf4}</p>
            <p>
              <strong>
                <a href="https://www.kodvizit.com/cekilis">
                  http://www.kodvizit.com/cekilis
                </a>
              </strong>
            </p>
          </Col>
          <Col md="3">
            <SoftwareSideMenu
              parentPage={PageType.CekilisSite}
              menuItems={[
                MenuItemType.Ozellikler,
                MenuItemType.EkranGoruntuleri,
              ]}
              activeMenuItem={MenuItemType.Ozellikler}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
